import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import VideoCall from "@material-ui/icons/VideoCall";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Badge, CircularProgress, Collapse, Hidden } from "@material-ui/core";
import Loader from "react-loader-advanced";
import { removeAuthObjects } from "../../utils";
import appLogo from "../../assets/img/Fronic_Logo_white.png";

//icons
import HomeIcon from "@material-ui/icons/Home";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ConfirmModal from "../../components/Common/CommonModal";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as userActions from "../Profile/actions";
import * as commonActions from "../common/actions";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import dailymotion from "../../assets/img/dailymotion.png";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BuildIcon from "@material-ui/icons/Build";
import PortraitIcon from "@material-ui/icons/Portrait";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notification from "./Notification";
import GavelIcon from "@material-ui/icons/Gavel";
import VpnLockIcon from '@material-ui/icons/VpnLock';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HearingIcon from "@material-ui/icons/Hearing";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import LyricsIcon from "@mui/icons-material/Lyrics";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { fronicMedia_FAQ, webSeriesDestribution } from "../../constants";

const drawerWidth = 240;
const gradientColor =
  "linear-gradient(90deg, rgba(19,153,121,1) 0%, rgba(18,180,161,1) 45%, rgba(5,150,179,1) 100%)";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    borderLeft: "none",
    boxShadow: "none",
    border: "none",
    background: "#2d2d2d",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    background: "#2d2d2d",
    width: drawerWidth,
    borderRight: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
  },
  drawerClose: {
    background: "#2d2d2d",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "300ms",
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  imageDiv: {
    width: "100%",
    display: "flex",
  },
  imglogo: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  activelistItem: {
    background: gradientColor,
  },
  iconAndLabelFont: {
    color: "white",
    fontFamily: "'DM Sans', sans-serif",
  },
  nestedIconAndLabel: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function MainContainer(props) {
  const classes = useStyles();
  const { path } = props.match;
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [expendTools, setExpendTools] = useState(false);
  const [expendRights, setExpendRights] = useState(false);

  const [loader, setLoader] = useState(false);
  const [confirmOpen, setConfimOpen] = useState(false);
  const [userRevenue, setUserRevenue] = useState(0.0);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [notification, setNotification] = useState([]);
  const [offset, setOffset] = useState(0);
  const [runPagination, setRunPagination] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const dropdownRef = useRef(null);
  const clickedOutside = useOutsideAlerter(dropdownRef);

  useEffect(() => {
    if (clickedOutside) {
      if (openNotification) {
        setOpenNotification(false);
      }
    }
  }, [clickedOutside]);

  useEffect(() => {
    if (document.body.clientWidth < 700) {
      if (open) setOpen(false);
    } else {
      if (!open) setOpen(true);
    }
    userActions.getUser().then((res) => {
      setUserRevenue((res.body && res.body.revenue && res.body.revenue) || 0);
    });
    commonActions.checkNotificationBell().then((res) => {
      setShowBadge(
        res &&
          res.body &&
          res.body.notification_icon &&
          res.body.notification_icon === "seen"
          ? false
          : true || true
      );
    });
    setInterval(() => {
      commonActions.checkNotificationBell().then((res) => {
        setShowBadge(
          res &&
            res.body &&
            res.body.notification_icon &&
            res.body.notification_icon === "seen"
            ? false
            : true || true
        );
      });
    }, 60000);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      if (document.body.clientWidth < 700) {
        if (open) setOpen(false);
      } else {
        if (!open) setOpen(true);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const listItems = [
    {
      label: "Dashboard",
      // icon: <img style={{ height: '30px' }} src={recipesIcon} alt='' />,
      icon: <HomeIcon />,
      selected: path === "/",
      path: "/",
    },
    {
      label: "Create Release",
      icon: <NewReleasesIcon />,
      selected:
        path === "/create_releases/:id" ||
        path === "/create_releases" ||
        path === "/select_release" ||
        path === "/create_video_releases",
      path: "/select_release",
    },
    {
      label: "Catalog",
      icon: <RecentActorsIcon />,
      selected:
        path === "/catalog/:id" ||
        path === "/catalog" ||
        path === "/video-catalog",
      path: "/catalog",
    },
    // {
    //     label: 'Social Media',
    //     icon: <AssignmentIcon />,
    //     path: '/social_media'
    // },
    {
      label: "Reports",
      icon: <AssignmentIcon />,
      selected: path === "/reports",
      path: "/reports",
    },
    {
      label: "Tickets",
      icon: <ConfirmationNumberIcon />,
      selected: path === "/tickets" || path === "/tickets/:id",
      path: "/tickets",
    },
    {
      label: "Withdrawal",
      icon: <MonetizationOnIcon />,
      selected: path === "/profile",
      path: "/profile",
    },
    {
      label: "Rights Manager",
      icon: <VpnLockIcon />,
      selected: path === "/rights",
      path: "/rights",
    },
    {
      label: "Tools",
      icon: <BuildIcon />,
      selected: path === "/tools",
      path: "/tools",
    },
    {
      label: "Invite",
      icon: <PersonAddIcon />,
      selected: path === "/invite",
      path: "/invite",
    },
    {
      label: "Distribute Web Series",
      icon: <VideoCall />,
      selected: false,
      path: webSeriesDestribution,
    },
    {
      label: "FAQ",
      icon: <LiveHelpIcon />,
      selected: false,
      path: fronicMedia_FAQ,
    },
    {
      label: "Logout",
      icon: <ExitToAppIcon />,
      path: "/login",
    },
  ];

  const header = () => {
    // const { path } = props.match
    switch (path) {
      case "/":
        return "Dashboard";
      case "/create_releases/:id":
      case "/create_releases":
        return "Create Release";
      case "/catalog/:id":
      case "/catalog":
        return "Audio Catalog";
      case "/video-catalog":
        return "Video Catalog";
      // case '/Social Media':
      //     return 'social_media'
      case "/tickets/:id":
      case "/tickets":
        return "Tickets";
      case "/reports":
        return "Reports";
      case "/invite":
        return "Invite";
      case "/rights/ugc-claims":
        return "UGC Claims";
      case "/rights/whitelist":
        return "Whitelist";
      case "/tools/profile-linking":
        return "Profile Linking";
      case "/tools/playlist-pitchment":
        return "Playlist Pitchment";
      case "/tools/distribute-lyrics":
        return "Distribute Lyrics";
      default: {
        return "";
      }
    }
  };

  const handleConfirmClickOpen = () => {
    setConfimOpen(true);
  };

  const handleConfirmClose = () => {
    setConfimOpen(false);
  };

  const onConfirmLogout = () => {
    onSetLoader(true);
    removeAuthObjects();
    // actions.clearStore()
    setTimeout(() => {
      onSetLoader(false);
      // return props.history.push('/login')
      window.open("https://fronicmedia.com", "_self");
    }, 1000);
  };

  const onSetLoader = (action) => {
    setLoader(action);
  };

  const onOpenNotification = () => {
    if (openNotification || clickedOutside) return;
    setOpenNotification(true);
    if (!notification.length || showBadge) {
      setNotification([]);
      getAllNotification(0);
    }
  };

  const getAllNotification = async (offset = 0) => {
    setNotificationLoader(true);
    await commonActions.getNotificationsForUser(offset).then((res) => {
      setShowBadge(false);
      setRunPagination(
        Array.isArray(res.body) && res.body.length === 0 ? false : true || true
      );
      setNotification((prev) =>
        Array.isArray(res.body) ? [...prev, ...res.body] : prev
      );
    });
    setNotificationLoader(false);
  };

  const scrollingNotification = (e) => {
    const { scrollHeight = 0, scrollTop = 0, scrollWidth = 0 } = e.target || {};

    if (
      scrollHeight - (scrollTop + scrollWidth) < 70 &&
      !notificationLoader &&
      runPagination
    ) {
      setOffset((prev) => prev + 20);
      getAllNotification(offset + 20);
    }
  };

  const LoaderStyle = {
    height: "100%",
    overflow: "hidden",
  };

  const openLinkInNewTab = () => {
    window.open("https://ambalaproductions.in/", "_blank");
  };

  return (
    <>
      <Loader
        className="main-loader"
        style={loader ? LoaderStyle : null}
        message={<CircularProgress size={50} style={{ color: "white" }} />}
        show={loader}
      >
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <div
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <Typography variant="h6" noWrap style={{ alignSelf: "center" }}>
                  {header()}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Hidden only={["xs"]}>
                    <div style={{ alignSelf: "center", display: "flex" }}>
                      {/* <p style={{ paddingRight: '23px', marginRight: '23px', fontSize: '18px', borderRight: '1px solid #d8d8d8' }}>WELCOME, ADMIN.</p> */}
                      {/* <Clock /> */}
                      <p style={{ fontSize: 18 }}>
                        Total Revenue: ${userRevenue}
                      </p>
                    </div>
                  </Hidden>
                  <Hidden only={["sm", "md", "lg", "xl"]}>
                    <div style={{ alignSelf: "center", display: "flex" }}>
                      <p style={{ fontSize: 18 }}>${userRevenue}</p>
                    </div>
                  </Hidden>
                  <AccountCircleIcon
                    style={{ marginLeft: 15, cursor: "pointer" }}
                    onClick={() => history.push("/profile")}
                  />
                  <IconButton color="inherit" onClick={onOpenNotification}>
                    <Badge
                      badgeContent={showBadge ? "new" : 0}
                      color="secondary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  {openNotification ? (
                    <div
                      className="notification-container"
                      ref={dropdownRef}
                      onScroll={scrollingNotification}
                    >
                      <Notification
                        notification={notification}
                        notificationLoader={notificationLoader}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            style={{ position: "relative" }}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <div
                className={classes.imageDiv}
                style={{ height: open ? "100px" : "40px" }}
              >
                <img
                  className={classes.imglogo}
                  src={appLogo}
                  alt=""
                  onClick={() => props.history.push("/")}
                />
              </div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon className={classes.iconAndLabelFont} />
                ) : (
                  <ChevronLeftIcon className={classes.iconAndLabelFont} />
                )}
              </IconButton>
            </div>
            {/* <Divider /> */}
            <div style={{ color: "white" }}>
              <span>An </span>
              <span
                onClick={() =>
                  window.open("https://ambalaproductions.in/", "_blank")
                }
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "inherit",
                  transition: "color 0.2s",
                }}
              >
                Ambala Production's Product
              </span>
            </div>
            <List style={{ padding: 0 }}>
              {listItems.map((text, index) => {
                const isActive = text.selected;
                if (text.label === "Logout") {
                  return (
                    <ListItem
                      className={isActive ? classes.activelistItem : ""}
                      button
                      key={text.label}
                      onClick={handleConfirmClickOpen}
                    >
                      <ListItemIcon
                        className={`${classes.iconAndLabelFont} font-family`}
                      >
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={classes.iconAndLabelFont}
                        primary={text.label}
                      />
                    </ListItem>
                  );
                } else if (text.label === "FAQ") {
                  return (
                    <a
                      href={text.path}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem
                        className={isActive ? classes.activelistItem : ""}
                        button
                        key={text.label}
                      >
                        <ListItemIcon
                          className={`${classes.iconAndLabelFont} font-family`}
                        >
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.iconAndLabelFont}
                          primary={text.label}
                        />
                      </ListItem>
                    </a>
                  );
                } else if (text.label === "Distribute Web Series") {
                  return (
                    <a
                      href={text.path}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <ListItem
                        className={isActive ? classes.activelistItem : ""}
                        button
                        key={text.label}
                      >
                        <ListItemIcon
                          className={`${classes.iconAndLabelFont} font-family`}
                        >
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.iconAndLabelFont}
                          primary={text.label}
                        />
                      </ListItem>
                    </a>
                  );
                } else if (text.label === "Tools") {
                  return (
                    <>
                      <ListItem
                        button
                        key={text.label}
                        onClick={() => setExpendTools((prev) => !prev)}
                      >
                        <ListItemIcon className={classes.iconAndLabelFont}>
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={`${classes.iconAndLabelFont} font-family`}
                          primary={text.label}
                        />
                        {expendTools ? (
                          <ExpandLess className={classes.iconAndLabelFont} />
                        ) : (
                          <ExpandMore className={classes.iconAndLabelFont} />
                        )}
                      </ListItem>
                      <Collapse in={expendTools} timeout="auto" unmountOnExit>
                        <ListItem
                          button
                          className={
                            path === "/tools/profile-linking"
                              ? classes.activelistItem
                              : ""
                          }
                          onClick={() =>
                            props.history.push("/tools/profile-linking")
                          }
                        >
                          <ListItemIcon
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel}`}
                          >
                            <SupervisorAccountIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel} font-family`}
                            primary="Profile Linking"
                          />
                        </ListItem>
                        <ListItem
                          button
                          className={
                            path === "/tools/playlist-pitchment"
                              ? classes.activelistItem
                              : ""
                          }
                          onClick={() =>
                            props.history.push("/tools/playlist-pitchment")
                          }
                        >
                          <ListItemIcon
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel}`}
                          >
                            <HearingIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel} font-family`}
                            primary="Playlist Pitchment"
                          />
                        </ListItem>
                        <ListItem
                          button
                          className={
                            path === "/tools/distribute-lyrics"
                              ? classes.activelistItem
                              : ""
                          }
                          onClick={() =>
                            props.history.push("/tools/distribute-lyrics")
                          }
                        >
                          <ListItemIcon
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel}`}
                          >
                            <LyricsIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel} font-family`}
                            primary="Distribute Lyrics"
                          />
                        </ListItem>
                      </Collapse>
                    </>
                  );
                } else if (text.label === "Rights Manager") {
                  return (
                    <>
                      <ListItem
                        button
                        key={text.label}
                        onClick={() => setExpendRights((prev) => !prev)}
                      >
                        <ListItemIcon className={classes.iconAndLabelFont}>
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={`${classes.iconAndLabelFont} font-family`}
                          primary={text.label}
                        />
                        {expendRights ? (
                          <ExpandLess className={classes.iconAndLabelFont} />
                        ) : (
                          <ExpandMore className={classes.iconAndLabelFont} />
                        )}
                      </ListItem>
                      <Collapse in={expendRights} timeout="auto" unmountOnExit>
                        <ListItem
                          button
                          className={
                            path === "/rights/ugc-claims"
                              ? classes.activelistItem
                              : ""
                          }
                          onClick={() =>
                            props.history.push("/rights/ugc-claims")
                          }
                        >
                          <ListItemIcon
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel}`}
                          >
                            <GavelIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel} font-family`}
                            primary="UGC Claims"
                          />
                        </ListItem>

                        <ListItem
                          button
                          className={
                            path === "/rights/whitelist"
                              ? classes.activelistItem
                              : ""
                          }
                          onClick={() =>
                            props.history.push("/rights/whitelist")
                          }
                        >
                          <ListItemIcon
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel}`}
                          >
                            <PlaylistPlayIcon />
                          </ListItemIcon>
                          <ListItemText
                            className={`${classes.iconAndLabelFont} ${classes.nestedIconAndLabel} font-family`}
                            primary="Whitelist"
                          />
                        </ListItem>
                      </Collapse>
                    </>
                  );
                } else {
                  return (
                    <ListItem
                      className={isActive ? classes.activelistItem : ""}
                      button
                      key={text.label}
                      onClick={() => props.history.push(text.path)}
                    >
                      <ListItemIcon className={classes.iconAndLabelFont}>
                        {text.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={`${classes.iconAndLabelFont} font-family`}
                        primary={text.label}
                      />
                    </ListItem>
                  );
                }
              })}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {React.cloneElement(props.children, { setLoader: onSetLoader })}
          </main>
          <ConfirmModal
            message={`Are you sure you want to Logout?`}
            open={confirmOpen}
            title={`Confirm Logout`}
            onSubmitConfirm={onConfirmLogout}
            handleClose={handleConfirmClose}
          ></ConfirmModal>
        </div>
      </Loader>
    </>
  );
}
