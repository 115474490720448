import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Login from './containers/Auth/Login';
import Signup from './containers/Auth/Signup';
import Catalog from './containers/Catalog';
import VideoCatalog from './containers/VideoCatalog';
import ViewRelease from './containers/Catalog/ViewRelease';
import CreateReleases from './containers/Releases/CreateReleases';
import VideoReleases from './containers/Releases/VideoReleases';
import Dashboard from './containers/Dashboard';
import MainContainer from './containers/Main';
import Reports from './containers/Reports';
import Tickets from './containers/Tickets';
import ViewTicket from './containers/Tickets/ViewTicket';
import { isLoggedIn } from './utils';
import Profile from "./containers/Profile"
import Invite from './containers/Invite';
import Tools from './containers/Tools';
import releaseCheck from './containers/Releases'
import RightsManager from './containers/RightsManager';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        <MainContainer {...props}>
          <Component {...props} />
        </MainContainer>
      )
        : (
          <Redirect to='/login' />
        )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

export default function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/signup" component={Signup} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/create_releases/:id" component={CreateReleases} />
        <PrivateRoute exact path="/create_releases" component={CreateReleases} />
        <PrivateRoute exact path="/select_release" component={releaseCheck} />
        <PrivateRoute exact path="/create_video_releases" component={VideoReleases} />
        <PrivateRoute exact path="/catalog/:id" component={ViewRelease} />
        <PrivateRoute exact path="/catalog" component={Catalog} />
        <PrivateRoute exact path="/video-catalog" component={VideoCatalog} />
        <PrivateRoute exact path="/invite" component={Invite} />
        {/* <PrivateRoute exact path="/social_media" component={SocialMedia} /> */}
        <PrivateRoute exact path="/tickets" component={Tickets} />
        <PrivateRoute exact path="/tickets/:id" component={ViewTicket} />
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/tools/profile-linking" component={Tools} />
        <PrivateRoute exact path="/tools/playlist-pitchment" component={Tools} />
        <PrivateRoute exact path="/tools/distribute-lyrics" component={Tools} />
        <PrivateRoute exact path="/rights/ugc-claims" component={RightsManager} />
        <PrivateRoute exact path="/rights/whitelist" component={RightsManager} />
      </Switch>
    </Router>
  );
}
